@media screen and (min-width: 959px){}


@media screen and (max-width: 480px) {
    .pie-chart {
        position: relative;
        width: 250px;
        height: 250px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2em;
        color: #676767;
    }
}