@media screen and (min-width: 959px){
    .admin-user-view {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .graph-section {
        position: relative;
        width: 50%;
        height: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .graph-section canvas {
        position: relative;
        width: 100%;
    }
    .status-section {
        position: relative;
        width: 50%;
    }
    .status-section .section-title {
        position: relative;
        width: 100%;
        display: flex;
        border-bottom: 1px solid #d0d0d0;
        color: #676767;
        font-size: 1.2em;
        margin-bottom: 50px;
    }
    .status-section .user-name {
        position: relative;
        width: 100%;
        display: flex;
        height: 45px;
        align-items: center;
    }
    .user-name .title,
    .registration_date .title {
        position: relative;
        width: 150px;
        height: 100%;
        display: flex;
        align-items: center;
        color: #676767;
    }
    .user-name .content,
    .registration_date .content {
        position: relative;
        width: 300px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #d0d0d0;
        color: #676767;
    }
    .registration_date {
        position: relative;
        width: 100%;
        height: 45px;
        display: flex;
        align-items: center;
    }

}