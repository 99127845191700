body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}
button {
  cursor: pointer;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media screen and (min-width: 959px){
    .login-view {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center; 
        background-color: rgba(207, 238, 255, 0.75)
    }

    
    .login-body {
        position: relative;
        width: 300px;
        height: 400px;
        background-color: #fff;
        border-radius: 5px;
        margin-top: 140px; 
    }
    .login-body .login-title {
        position: relative;
        font-size:  1.5em;
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        color: #676767;
    }
    .login-widget {
        position: relative;
        width: 100%;
        height: calc(100% - 40px);
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .widget-title,
    .widget-item {
        position: relative;
        width: 250px; 
        height: 35px;
        color: #676767;
    }
    .widget-item {
        position: relative;
        border: 1px solid #d0d0d0;
        border-radius: 5px;
        display: flex;
        align-items: center;
    }
    .widget-item input {
        border: none;
        width: 100%;
        height: calc(100% - 5px);
        outline: none;
        color: #676767;
    }
    .widget-item button {
        background-color: transparent;
        border: none;
    }
    .widget-item button svg {
        color: #676767;
        fill: #676767;
    }
    .login-button {
        color: #ffffff;
        background-color: rgba(46, 167, 255, 1);
        width: 250px;
        height: 35px;
        border-radius: 5px;
        border: none;
        margin-top: 75px;
    }
}

@media screen and (max-width: 959px){}

@media screen and (max-width: 480px) {
    html {
        overflow: hidden;
    }
    .login-view {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center; 
        background-color: rgba(207, 238, 255, 0.75)
    }

    
    .login-body {
        position: relative;
        width: 300px;
        height: 400px;
        background-color: #fff;
        border-radius: 5px;
        margin-top: 140px; 
    }
    .login-body .login-title {
        position: relative;
        font-size:  1.5em;
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        color: #676767;
    }
    .login-widget {
        position: relative;
        width: 100%;
        height: calc(100% - 40px);
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .widget-title,
    .widget-item {
        position: relative;
        width: 250px; 
        height: 35px;
        color: #676767;
    }
    .widget-item {
        position: relative;
        border: 1px solid #d0d0d0;
        border-radius: 5px;
        display: flex;
        align-items: center;
    }
    .widget-item input {
        border: none;
        width: 100%;
        height: calc(100% - 5px);
        outline: none;
        color: #676767;
    }
    .widget-item button {
        background-color: transparent;
        border: none;
    }
    .widget-item button svg {
        color: #676767;
        fill: #676767;
    }
    .login-button {
        color: #ffffff;
        background-color: rgba(46, 167, 255, 1);
        width: 250px;
        height: 35px;
        border-radius: 5px;
        border: none;
        margin-top: 75px;
    }

}
@media screen and (min-width: 959px){}


@media screen and (max-width: 480px) {
    .pie-chart {
        position: relative;
        width: 250px;
        height: 250px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2em;
        color: #676767;
    }
}
@media screen and (min-width: 959px) {
    .admin-home-view {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .admin-home-view table {
        position: relative;
        width: 90%;
        border-collapse: collapse;
        table-layout: fixed;
        color: #676767;
        height: 870px;
    }
    .admin-home-view table thead tr {
        position: relative;
        width: 100%;
        border-bottom: 1px solid #d0d0d0;
    }
    .admin-home-view table thead {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .admin-home-view table thead td {
        font-size: 1.0em;
    }
  
    .admin-home-view table tbody {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
    }
    .admin-home-view table tbody td{
        position: relative;
        height: 50px
    }
    .admin-home-view table thead td:nth-child(1),
    .admin-home-view table tbody td:nth-child(1) {
       width: 150px;
    }
    .admin-home-view table thead td:nth-child(2),
    .admin-home-view table tbody td:nth-child(2) {
       width: 400px;
    }
    .admin-home-view table thead td:nth-child(3),
    .admin-home-view table tbody td:nth-child(3) {
       width: 150px;
    }
    .admin-home-view table thead td:nth-child(4),
    .admin-home-view table tbody td:nth-child(4) {
       width: 150px;
    }
    .admin-home-view table thead td:nth-child(5),
    .admin-home-view table tbody td:nth-child(5) {
       width: 150px;
    }
    .admin-home-view table thead td:nth-child(6),
    .admin-home-view table tbody td:nth-child(6) {
       width: 150px;
    }
    .admin-home-view table .registration-button {
        background-color: rgba(46, 167, 255, 1);
        color: #fff;
        border: none;
        height: 35px;
        width: 150px;
        border-radius: 10px;
    }
    .admin-home-view table .user-page-button {
        background-color: rgba(148, 184, 49, 1);
        color: #fff;
        border: none;
        height: 35px;
        width: 150px;
        border-radius: 10px;
    }


}
@media screen and (max-width: 959px) {}
@media screen and (max-width: 480px) {
    .home-view {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .home-view .top {
        position: relative;
        width: 100%;
        height: 350px;
        display: flex;
        flex-direction: column;
    }
    .top .title {
        height: 50px;
        margin-left: 15px;
        font-size: 1.2em;
        display: flex;
        align-items: center;
        color: #676767;
    }
    .top .graph {
        position: relative;
        width: 100%;
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .top .swiper {
        position: relative;
        width: 100%;
        height: 100%;
    }
    .top .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .menu {
        position: relative;
        width: 100%;
        height: clac(100% - 350px);
        display: flex;
        flex-direction: column;
        align-items: center;
        
    }
    .menu ul {
        position: relative;
        width: 90%;
        list-style: none;
        padding: 0;
    }
    .menu ul li {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .menu li a {
        position: relative;
        width: 350px;
        height: 50px;
        background-color: transparent;
        border: none;
        border-radius: 10px;
        border: 1px solid #d0d0d0;
    }
    .menu li a span {
        position: absolute;
        top: 0;
        right: 15px;
        display: flex;
        align-items: center;
        height: 100%;
        color: #676767;
        
    }
    .menu li .button-title {
        top: 0;
        left: 15px;
    }
    .menu li svg {
        color: #676767;
        fill: #676767
    }
    .modal-background {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
    }
    .prompt-modal {
        position: relative;
        width:250px;
        height: 200px;
        background-color: #fff;
        border-radius: 5px;
        margin-left: calc((100% - 250px) / 2);
        margin-top: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .modal-header {
        position: relative;
        width: 100%;
        height: 40px;
    }
    .modal-header button {
        position: relative;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-self: center;
        border: none;
        background-color: transparent;
    }

    .modal-header button span svg {
        color: #676767;
        fill: #676767;
    }
    .modal-body {
        position: relative;
        width: 100%;
        height:  calc(100% - 50px);
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .modal-body .input-section {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
    }
    .input-section input {
        position: relative;
        width: 200px;
        height: 30px;
        font-size: 1.2em;
    }
    .input-section button {
        position: relative;
        width: 150px;
        height: 45px;
        font-size: 1.0em;
        border-radius: 10px;
        background-color: rgba(46, 167, 255, 1);
        color: #fff;
        margin-top: 35px;
    }

}
@media screen and (max-width: 480px){
    .history-view {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .history-header {
        position: relative;
        width: 100%;
        height: 50px;
    }
    .history-header a {
        position: relative;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-self: center;
        margin-left: 15px;
    }
    .history-header a span svg {
        position: relative;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-self: center;
        color: rgba(46, 167, 255, 1);
    }
    .history-graph {
        position: relative;
        width: 95%;
        height: 300px;
        display: flex; 
        flex-direction: column;
        align-items: center;
    }
    .history-graph canvas {
        position: relative;
        height: 200px!important;
    }
    .history-table {
        position: relative;
        width: 95%;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .history-table ul {
        position: relative;
        width: 100%;
        list-style: none;
        padding: 0;
    }
  
    .history-div {
        position: relative;
        width: calc(100% - 2px);
        border: solid 1px #d0d0d0;
        height: 75px;
        display: flex;
        border-radius: 5px;
        margin-bottom: 15px;
        flex-direction: column;
    }
    .history-div span {
        position: relative;
        width: 150px;
        margin-left: 15px;
        font-size:1.1em;
        color: #676767;
        height: 25px;
        display: flex;
        align-items: center;
    }
    .history-div .visual {
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }
    .visual span {
        font-size: 1.2em;
    }
    .visual .gege-span {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }
     .gage {
        position: relative;
        width:  200px;
        height: 3px;
        border: solid 1px #d0d0d0;
        border-radius: 1.5em;
        overflow: hidden;
    }
    .gage .bar {
        position: relative;
        height: 100%;
        background-color: rgba(46, 167, 255, 1);
        margin-left: 0;
    }

}
@media screen and (min-width: 959px) {
    .admin-view {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .admin-view header {
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }
    .admin-view header .title {
        position: relative;
        margin-left:  15px;
        font-size: 1.2em;
        color: #676767;
    }
    .admin-view header button {
        position: absolute;
        right: 50px;
        width: 200px;
        height: 35px;
        border: none;
        border-radius: 10px;
        background-color: rgba(46, 167, 255, 1);
        color: #fff;

    }
    .admin-view .admin-body {
        position: relative;
        width: 100%;
        height: calc(100% - 50px);
        display: flex;
    }
    .admin-menu {
        position: relative;
        width: 90px;
        height: calc(100% - 60px);
        margin-top: 30px;
        margin-left: 15px;

    }
    .admin-menu-div {
        position: relative;
        width: calc(100% - 3px);
        height: calc(100% - 3px);
        box-shadow: 0 0 3px 3px #d0d0d0; 
        border-radius: 5px;
    }
    .admin-menu-div ul {
        position: relative;
        width: 100%;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .admin-menu-div a {
        position: relative;
        width: 75px;
        height: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .admin-content {
        position: relative;
        width: calc(100% - 120px);
        height: 100%;
    }


}
@media screen and (min-width: 959px){
    .modal-background {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10;
    }
    .registration-modal {
        position: relative;
        width: 650px;
        height: 400px;
        background-color: #fff;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: calc((100% - 650px) / 2);
        margin-top: 150px
    }
    .modal-header {
        position: relative;
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
    }
    .modal-header .close-button {
        border: none;
        background-color: transparent;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .modal-header .close-button svg {
        fill: #676767;
        color: #676767;
    }
    .modal-body {
        position: relative;
        width: 100%;
        height: calc(100% - 40px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .registration-modal .modal-body {
        justify-content: flex-start;
    }
    .registration-modal .title {
        position: relative;
        color: #676767;
    }

    .registration-modal .first-section,
    .registration-modal .second-section {
        position: relative;
        width: 90%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    .registration-modal .first-section span,
    .registration-modal .second-section span {
        position: relative;
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #676767;
    }
    .registration-modal .modal-body input {
        position: relative;
        width: 200px;
        height: 35px;
        border: none;
        border: solid 1px #d0d0d0;
        border-radius: 5px
    }
    .registration-modal .modal-body button {
        position: relative;
        width: 150px;
        height: 35px;
        border: none;
        background-color: rgba(46, 167, 255, 1);
        border-radius:  10px;
        color: #fff;
        margin-top: 25px;
    }
    .setup-modal {
        position: relative;
        width: 400px;
        height: 250px;
        background-color: #fff;
        margin-left: calc((100% - 400px) / 2);
        margin-top: 100px;
        border-radius:  5px;
    }
    .setup-modal span {
        color: #676767;
    }
    .setup-modal .button-section {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
@media screen and (min-width: 959px){
    .admin-calendar-view {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .admin-calendar-view table {
        position: relative;
        width: 90%;
        height: 870px;
        display: block;
        table-layout: fixed;
        overflow: scroll;
        border-collapse: collapse;
    }
    .admin-calendar-view td {
        position: relative;
        width: 300px;
        height: 50px;
        padding: 5px 30px;
        color: #676767;
        z-index: 9;
    }
    .admin-calendar-view tr {
        z-index: 9;
    }
    .admin-calendar-view tr:nth-child(1){
        position: -webkit-sticky;
        position: sticky;
        z-index: 11;

    }
    .admin-calendar-view td:nth-child(1) {
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        background-color: #fff;
        z-index: 10;
        border-right: solid 1px #d0d0d0;
        box-sizing: border-box;
    }
    .admin-calendar-view tr:nth-child(1) td:nth-child(1){
        z-index: 15;
    }
    .admin-calendar-view table tr:nth-child(1) td {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        border-bottom: 1px solid #d0d0d0;
        width: 100px;
        height: 50px;
        background-color: #fff;
        z-index: 10;
    }
}
@media screen and (min-width: 959px){
    .admin-user-view {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .graph-section {
        position: relative;
        width: 50%;
        height: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .graph-section canvas {
        position: relative;
        width: 100%;
    }
    .status-section {
        position: relative;
        width: 50%;
    }
    .status-section .section-title {
        position: relative;
        width: 100%;
        display: flex;
        border-bottom: 1px solid #d0d0d0;
        color: #676767;
        font-size: 1.2em;
        margin-bottom: 50px;
    }
    .status-section .user-name {
        position: relative;
        width: 100%;
        display: flex;
        height: 45px;
        align-items: center;
    }
    .user-name .title,
    .registration_date .title {
        position: relative;
        width: 150px;
        height: 100%;
        display: flex;
        align-items: center;
        color: #676767;
    }
    .user-name .content,
    .registration_date .content {
        position: relative;
        width: 300px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #d0d0d0;
        color: #676767;
    }
    .registration_date {
        position: relative;
        width: 100%;
        height: 45px;
        display: flex;
        align-items: center;
    }

}
@media screen and (min-width: 959px) {
    .add-member-modal {
        position: relative;
        width: 600px;
        height: 500px;
        background-color: #fff;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: calc((100% - 500px) / 2);
        margin-top: 150px;
    }
    .modal-input-section {
        position: relative;
        width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .modal-input-section input,
    .modal-input-section span {
        position: relative;
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        color: #676767;
    }
    .modal-input-section input {
        position: relative;
        border: none;
        border: solid 1px #d0d0d0;
        border-radius: 5px;
    }
    .modal-body button {
        position: relative;
        width: 250px;
        height: 35px;
        margin-top: 20px;
        border: none;
        border-radius: 10px;
        color: #fff;
        background-color: #2EA7FF
    }
}
