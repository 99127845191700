@media screen and (min-width: 959px) {
    .admin-view {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .admin-view header {
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }
    .admin-view header .title {
        position: relative;
        margin-left:  15px;
        font-size: 1.2em;
        color: #676767;
    }
    .admin-view header button {
        position: absolute;
        right: 50px;
        width: 200px;
        height: 35px;
        border: none;
        border-radius: 10px;
        background-color: rgba(46, 167, 255, 1);
        color: #fff;

    }
    .admin-view .admin-body {
        position: relative;
        width: 100%;
        height: calc(100% - 50px);
        display: flex;
    }
    .admin-menu {
        position: relative;
        width: 90px;
        height: calc(100% - 60px);
        margin-top: 30px;
        margin-left: 15px;

    }
    .admin-menu-div {
        position: relative;
        width: calc(100% - 3px);
        height: calc(100% - 3px);
        box-shadow: 0 0 3px 3px #d0d0d0; 
        border-radius: 5px;
    }
    .admin-menu-div ul {
        position: relative;
        width: 100%;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .admin-menu-div a {
        position: relative;
        width: 75px;
        height: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .admin-content {
        position: relative;
        width: calc(100% - 120px);
        height: 100%;
    }


}