@media screen and (min-width: 959px){
    .modal-background {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10;
    }
    .registration-modal {
        position: relative;
        width: 650px;
        height: 400px;
        background-color: #fff;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: calc((100% - 650px) / 2);
        margin-top: 150px
    }
    .modal-header {
        position: relative;
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
    }
    .modal-header .close-button {
        border: none;
        background-color: transparent;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .modal-header .close-button svg {
        fill: #676767;
        color: #676767;
    }
    .modal-body {
        position: relative;
        width: 100%;
        height: calc(100% - 40px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .registration-modal .modal-body {
        justify-content: flex-start;
    }
    .registration-modal .title {
        position: relative;
        color: #676767;
    }

    .registration-modal .first-section,
    .registration-modal .second-section {
        position: relative;
        width: 90%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    .registration-modal .first-section span,
    .registration-modal .second-section span {
        position: relative;
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #676767;
    }
    .registration-modal .modal-body input {
        position: relative;
        width: 200px;
        height: 35px;
        border: none;
        border: solid 1px #d0d0d0;
        border-radius: 5px
    }
    .registration-modal .modal-body button {
        position: relative;
        width: 150px;
        height: 35px;
        border: none;
        background-color: rgba(46, 167, 255, 1);
        border-radius:  10px;
        color: #fff;
        margin-top: 25px;
    }
    .setup-modal {
        position: relative;
        width: 400px;
        height: 250px;
        background-color: #fff;
        margin-left: calc((100% - 400px) / 2);
        margin-top: 100px;
        border-radius:  5px;
    }
    .setup-modal span {
        color: #676767;
    }
    .setup-modal .button-section {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}