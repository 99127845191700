@media screen and (min-width: 959px) {
    .add-member-modal {
        position: relative;
        width: 600px;
        height: 500px;
        background-color: #fff;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: calc((100% - 500px) / 2);
        margin-top: 150px;
    }
    .modal-input-section {
        position: relative;
        width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .modal-input-section input,
    .modal-input-section span {
        position: relative;
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        color: #676767;
    }
    .modal-input-section input {
        position: relative;
        border: none;
        border: solid 1px #d0d0d0;
        border-radius: 5px;
    }
    .modal-body button {
        position: relative;
        width: 250px;
        height: 35px;
        margin-top: 20px;
        border: none;
        border-radius: 10px;
        color: #fff;
        background-color: #2EA7FF
    }
}