@media screen and (min-width: 959px){
    .admin-calendar-view {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .admin-calendar-view table {
        position: relative;
        width: 90%;
        height: 870px;
        display: block;
        table-layout: fixed;
        overflow: scroll;
        border-collapse: collapse;
    }
    .admin-calendar-view td {
        position: relative;
        width: 300px;
        height: 50px;
        padding: 5px 30px;
        color: #676767;
        z-index: 9;
    }
    .admin-calendar-view tr {
        z-index: 9;
    }
    .admin-calendar-view tr:nth-child(1){
        position: sticky;
        z-index: 11;

    }
    .admin-calendar-view td:nth-child(1) {
        position: sticky;
        left: 0;
        background-color: #fff;
        z-index: 10;
        border-right: solid 1px #d0d0d0;
        box-sizing: border-box;
    }
    .admin-calendar-view tr:nth-child(1) td:nth-child(1){
        z-index: 15;
    }
    .admin-calendar-view table tr:nth-child(1) td {
        position: sticky;
        top: 0;
        border-bottom: 1px solid #d0d0d0;
        width: 100px;
        height: 50px;
        background-color: #fff;
        z-index: 10;
    }
}