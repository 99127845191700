@media screen and (min-width: 959px){
    .login-view {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center; 
        background-color: rgba(207, 238, 255, 0.75)
    }

    
    .login-body {
        position: relative;
        width: 300px;
        height: 400px;
        background-color: #fff;
        border-radius: 5px;
        margin-top: 140px; 
    }
    .login-body .login-title {
        position: relative;
        font-size:  1.5em;
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        color: #676767;
    }
    .login-widget {
        position: relative;
        width: 100%;
        height: calc(100% - 40px);
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .widget-title,
    .widget-item {
        position: relative;
        width: 250px; 
        height: 35px;
        color: #676767;
    }
    .widget-item {
        position: relative;
        border: 1px solid #d0d0d0;
        border-radius: 5px;
        display: flex;
        align-items: center;
    }
    .widget-item input {
        border: none;
        width: 100%;
        height: calc(100% - 5px);
        outline: none;
        color: #676767;
    }
    .widget-item button {
        background-color: transparent;
        border: none;
    }
    .widget-item button svg {
        color: #676767;
        fill: #676767;
    }
    .login-button {
        color: #ffffff;
        background-color: rgba(46, 167, 255, 1);
        width: 250px;
        height: 35px;
        border-radius: 5px;
        border: none;
        margin-top: 75px;
    }
}

@media screen and (max-width: 959px){}

@media screen and (max-width: 480px) {
    html {
        overflow: hidden;
    }
    .login-view {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center; 
        background-color: rgba(207, 238, 255, 0.75)
    }

    
    .login-body {
        position: relative;
        width: 300px;
        height: 400px;
        background-color: #fff;
        border-radius: 5px;
        margin-top: 140px; 
    }
    .login-body .login-title {
        position: relative;
        font-size:  1.5em;
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        color: #676767;
    }
    .login-widget {
        position: relative;
        width: 100%;
        height: calc(100% - 40px);
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .widget-title,
    .widget-item {
        position: relative;
        width: 250px; 
        height: 35px;
        color: #676767;
    }
    .widget-item {
        position: relative;
        border: 1px solid #d0d0d0;
        border-radius: 5px;
        display: flex;
        align-items: center;
    }
    .widget-item input {
        border: none;
        width: 100%;
        height: calc(100% - 5px);
        outline: none;
        color: #676767;
    }
    .widget-item button {
        background-color: transparent;
        border: none;
    }
    .widget-item button svg {
        color: #676767;
        fill: #676767;
    }
    .login-button {
        color: #ffffff;
        background-color: rgba(46, 167, 255, 1);
        width: 250px;
        height: 35px;
        border-radius: 5px;
        border: none;
        margin-top: 75px;
    }

}