@media screen and (max-width: 480px){
    .history-view {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .history-header {
        position: relative;
        width: 100%;
        height: 50px;
    }
    .history-header a {
        position: relative;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-self: center;
        margin-left: 15px;
    }
    .history-header a span svg {
        position: relative;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-self: center;
        color: rgba(46, 167, 255, 1);
    }
    .history-graph {
        position: relative;
        width: 95%;
        height: 300px;
        display: flex; 
        flex-direction: column;
        align-items: center;
    }
    .history-graph canvas {
        position: relative;
        height: 200px!important;
    }
    .history-table {
        position: relative;
        width: 95%;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .history-table ul {
        position: relative;
        width: 100%;
        list-style: none;
        padding: 0;
    }
  
    .history-div {
        position: relative;
        width: calc(100% - 2px);
        border: solid 1px #d0d0d0;
        height: 75px;
        display: flex;
        border-radius: 5px;
        margin-bottom: 15px;
        flex-direction: column;
    }
    .history-div span {
        position: relative;
        width: 150px;
        margin-left: 15px;
        font-size:1.1em;
        color: #676767;
        height: 25px;
        display: flex;
        align-items: center;
    }
    .history-div .visual {
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }
    .visual span {
        font-size: 1.2em;
    }
    .visual .gege-span {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }
     .gage {
        position: relative;
        width:  200px;
        height: 3px;
        border: solid 1px #d0d0d0;
        border-radius: 1.5em;
        overflow: hidden;
    }
    .gage .bar {
        position: relative;
        height: 100%;
        background-color: rgba(46, 167, 255, 1);
        margin-left: 0;
    }

}