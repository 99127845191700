@media screen and (min-width: 959px) {
    .admin-home-view {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .admin-home-view table {
        position: relative;
        width: 90%;
        border-collapse: collapse;
        table-layout: fixed;
        color: #676767;
        height: 870px;
    }
    .admin-home-view table thead tr {
        position: relative;
        width: 100%;
        border-bottom: 1px solid #d0d0d0;
    }
    .admin-home-view table thead {
        position: sticky;
        top: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .admin-home-view table thead td {
        font-size: 1.0em;
    }
  
    .admin-home-view table tbody {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
    }
    .admin-home-view table tbody td{
        position: relative;
        height: 50px
    }
    .admin-home-view table thead td:nth-child(1),
    .admin-home-view table tbody td:nth-child(1) {
       width: 150px;
    }
    .admin-home-view table thead td:nth-child(2),
    .admin-home-view table tbody td:nth-child(2) {
       width: 400px;
    }
    .admin-home-view table thead td:nth-child(3),
    .admin-home-view table tbody td:nth-child(3) {
       width: 150px;
    }
    .admin-home-view table thead td:nth-child(4),
    .admin-home-view table tbody td:nth-child(4) {
       width: 150px;
    }
    .admin-home-view table thead td:nth-child(5),
    .admin-home-view table tbody td:nth-child(5) {
       width: 150px;
    }
    .admin-home-view table thead td:nth-child(6),
    .admin-home-view table tbody td:nth-child(6) {
       width: 150px;
    }
    .admin-home-view table .registration-button {
        background-color: rgba(46, 167, 255, 1);
        color: #fff;
        border: none;
        height: 35px;
        width: 150px;
        border-radius: 10px;
    }
    .admin-home-view table .user-page-button {
        background-color: rgba(148, 184, 49, 1);
        color: #fff;
        border: none;
        height: 35px;
        width: 150px;
        border-radius: 10px;
    }


}
@media screen and (max-width: 959px) {}
@media screen and (max-width: 480px) {
    .home-view {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .home-view .top {
        position: relative;
        width: 100%;
        height: 350px;
        display: flex;
        flex-direction: column;
    }
    .top .title {
        height: 50px;
        margin-left: 15px;
        font-size: 1.2em;
        display: flex;
        align-items: center;
        color: #676767;
    }
    .top .graph {
        position: relative;
        width: 100%;
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .top .swiper {
        position: relative;
        width: 100%;
        height: 100%;
    }
    .top .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .menu {
        position: relative;
        width: 100%;
        height: clac(100% - 350px);
        display: flex;
        flex-direction: column;
        align-items: center;
        
    }
    .menu ul {
        position: relative;
        width: 90%;
        list-style: none;
        padding: 0;
    }
    .menu ul li {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .menu li a {
        position: relative;
        width: 350px;
        height: 50px;
        background-color: transparent;
        border: none;
        border-radius: 10px;
        border: 1px solid #d0d0d0;
    }
    .menu li a span {
        position: absolute;
        top: 0;
        right: 15px;
        display: flex;
        align-items: center;
        height: 100%;
        color: #676767;
        
    }
    .menu li .button-title {
        top: 0;
        left: 15px;
    }
    .menu li svg {
        color: #676767;
        fill: #676767
    }
    .modal-background {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
    }
    .prompt-modal {
        position: relative;
        width:250px;
        height: 200px;
        background-color: #fff;
        border-radius: 5px;
        margin-left: calc((100% - 250px) / 2);
        margin-top: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .modal-header {
        position: relative;
        width: 100%;
        height: 40px;
    }
    .modal-header button {
        position: relative;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-self: center;
        border: none;
        background-color: transparent;
    }

    .modal-header button span svg {
        color: #676767;
        fill: #676767;
    }
    .modal-body {
        position: relative;
        width: 100%;
        height:  calc(100% - 50px);
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .modal-body .input-section {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
    }
    .input-section input {
        position: relative;
        width: 200px;
        height: 30px;
        font-size: 1.2em;
    }
    .input-section button {
        position: relative;
        width: 150px;
        height: 45px;
        font-size: 1.0em;
        border-radius: 10px;
        background-color: rgba(46, 167, 255, 1);
        color: #fff;
        margin-top: 35px;
    }

}